<!--
 功能名称:千里眼平台-技师分析
 开发人:梁高权
 创建时间:2021/03/25
 最后修改时间:2021/03/25
-->
<template>
  <div class="tech-analysis-container">
    <tab-header :headerList="boss.techHeader" :currentTab="1" @changeTabPage="changeTabPage"></tab-header>
    <technician-turnover></technician-turnover>
    <technician-statistics></technician-statistics>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default{
  name:"technician-state",
  computed:{
    ...mapState(['boss'])
  },
  components:{
    'tab-header':() => import('@/components/tab-header'),
    'technician-turnover':() => import('./technician-turnover'),
    'technician-statistics':() => import('./technician-statistics')
  },
  methods:{
    changeTabPage(index){
      this.$emit("changeTab",index)
      this.currentTab = index
    }
  }
}
</script>

<style lang="scss" type="text/scss" scoped>
@import "~@/assets/style/public.scss";
.tech-analysis-container{
    flex:1;
    display:flex;
    flex-direction:column;
}
</style>
